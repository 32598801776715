import { useEffect } from 'react';

/**
 * A utility function that scrolls to the target element with the given hash as id
 * @param hash - the hash of the target element
 * @param scrollOptions - optional scroll options, e.g. { behavior: 'smooth', block: 'start' }
 */
export function scrollToHashId(hash: string, scrollOptions?: ScrollIntoViewOptions) {
  // Decode the hash and remove the leading #
  const decodedHash = decodeURIComponent(hash)?.replace('#', '');
  // If the hash is empty, return
  if (!decodedHash) return;
  // Find the element with the decoded hash and scroll to it
  // Usually we could use selector '#${decodedHash}',
  // but it does not work with special characters, when the ifd starts with a number, etc.
  // using the attribute selector [id="${decodedHash}"] is more reliable in that case
  const element = document.querySelector(`[id="${decodedHash}"]`);
  element?.scrollIntoView(scrollOptions);
}

/**
 * A custom hook that scrolls to the target element when the page is loaded with a deep link
 * Usually we could rely on the browser to do this, but in the case of the Next.js
 * client side navigation, the browser does not always scroll to the target element
 * as it should.
 */
export function useAutoScrollToHash() {
  useEffect(() => {
    if (window.location.hash) {
      scrollToHashId(window.location.hash);
    }
  }, []);
}
