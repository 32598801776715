import { MouseEventHandler } from 'react';
import Link from 'next/link';
import { MDXRemote, MDXRemoteProps, MDXRemoteSerializeResult } from 'next-mdx-remote';
import { scrollToHashId } from 'src/hooks/use-auto-scroll-to-hash';
import { getAbsoluteUrl } from 'src/utilities/url-helpers';
import { isEmail } from 'src/utilities/validator';

/**
 * Components that are used to customize the MarkDown rendering
 * e.g. <h1>Title</h1> becomes <h1 className="dg-text-medium-2 mb-8 text-center">Title</h1>
 */
const markdownComponents: MDXRemoteProps['components'] = {
  /**
   * Markdown links are allowed with some restrictions:
   * - Email addresses should always be converted to mailto links
   * - External links should always be opened in a new tab with rel="nofollow"
   * - Internal links should be handled by Next.js and hide the main navigation
   * It is the responsibility of the translators to ensure that all links are valid and are never linking to any pages
   * that could potentially lead to a purchase of a ticket as per iOS App Store guidelines.
   */
  a: ({ href, children }) => {
    // If the link starts with a hash and is part of a nav element, an onClick handler is added to scroll to the target element
    if (href?.startsWith('#')) {
      const onClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
        if (event.target instanceof HTMLElement && event.target.closest('nav')) {
          event.preventDefault();
          scrollToHashId(href, { behavior: 'smooth', block: 'start' });
        }
      };

      return (
        <a href={href} onClick={onClick}>
          {children}
        </a>
      );
    }

    // If the link is internal, use the router and add the `?hideNav=1` query param
    if (href?.startsWith('/')) {
      // Create a new url based on the href and add the hideNav query param
      const url = new URL(getAbsoluteUrl(href));
      url.searchParams.set('hideNav', '1');
      return (
        <Link href={url} prefetch={false}>
          {children}
        </Link>
      );
    }

    // If the link is email, make sure to use the mailto protocol
    if (href && isEmail(href)) {
      // Check if the href already has a mailto protocol
      const mailtoHref = href.startsWith('mailto') ? href : `mailto:${href}`;
      return <a href={mailtoHref}>{children}</a>;
    }

    // Otherwise the link is external and should be opened in a new tab
    return (
      <a href={href} target="_blank" rel="nofollow noreferrer">
        {children}
      </a>
    );
  },
  blockquote: (props) => <blockquote className="dg-text-light-3 mb-8 text-textTertiary lg:mb-14" {...props} />,
  h1: ({ children, ...rest }) => (
    <h1 className="dg-text-medium-2 mb-8 text-center" {...rest}>
      {children}
    </h1>
  ),
  h2: ({ children, ...rest }) => (
    <h2 className="dg-text-medium-3 mb-4" {...rest}>
      {children}
    </h2>
  ),
  h3: ({ children, ...rest }) => (
    <h3 className="dg-text-medium-4 mb-4" {...rest}>
      {children}
    </h3>
  ),
  h4: ({ children, ...rest }) => (
    <h4 className="dg-text-medium-5 mb-4" {...rest}>
      {children}
    </h4>
  ),
  hr: (props) => <hr className="my-8 opacity-25" {...props} />,
  li: ({ children }) => <li>{children}</li>,
  nav: ({ children }) => <nav className="dg-text-regular-6 mb-8 [&_a]:underline">{children}</nav>,
  p: (props) => (
    <p className="dg-text-regular-6 mb-4 text-textTertiary [&>a]:text-white [&>a]:text-opacity-100 [&>a]:underline">
      {props.children}
    </p>
  ),
  ol: ({ children }) => <ol className="mb-4 space-y-3 text-textTertiary">{children}</ol>,
  ul: (props) => <ul className="dg-text-regular-6 mb-4 list-inside list-disc text-textTertiary" {...props} />,
};

export type MarkdownProps = {
  /**
   * The main content of the page that is rendered as Markdown
   */
  markdown: MDXRemoteSerializeResult;
};

/**
 * Render serialized MDX/Markdown with custom styles.
 *
 * @example
 * ```jsx
 * <Markdown markdown={serializedMdx} />
 * ```
 */
export function Markdown({ markdown }: MarkdownProps) {
  return <MDXRemote {...markdown} components={markdownComponents} />;
}
