import { createContext, useContext, useState, type ComponentProps, type ReactNode, type JSX } from 'react';
import clsx from 'clsx';
import styles from './accordion.module.css';

type AccordionContextType = {
  selectedId?: string;
  onItemClick: (id: string) => void;
};

const AccordionContextInitial: AccordionContextType = {
  selectedId: undefined,
  onItemClick: () => {
    /* Do nothing by default */
  },
};

const AccordionContext = createContext(AccordionContextInitial);

const useAccordionContext = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error('useAccordionContext must be used within the Accordion component');
  }
  return context;
};

type AccordionPanelProps = ComponentProps<'div'> & {
  /** Container element ID (also used to manage open state so it’s required) */
  id: string;
  /** Panel button label */
  label: ({ open }: { open: boolean }) => ReactNode;
  /** HTML element used to render panel heading */
  headingElement?: JSX.ElementType;
  /** Panel body */
  children: ReactNode;
};

export function AccordionPanel({ id, label, headingElement = 'h3', children, ...rest }: AccordionPanelProps) {
  const { selectedId, onItemClick } = useAccordionContext();
  const open = id === selectedId;

  const handleClick = () => {
    onItemClick(id);
  };

  const Heading = headingElement;

  return (
    <div id={id} {...rest}>
      <Heading>
        <button
          aria-expanded={open}
          onClick={handleClick}
          className="block w-full rounded-sm text-left text-textSecondary outline-offset-2 typo-body-2 hover:text-textPrimary focus-visible:focus-outline"
        >
          {label({ open })}
        </button>
      </Heading>
      <div
        className={clsx(styles.panel, '-mx-2 overflow-hidden px-2 text-textSecondary typo-body-2')}
        hidden={open === false}
      >
        {children}
      </div>
    </div>
  );
}

type AccordionProps = ComponentProps<'div'> & {
  children?: ReactNode;
};

/**
 * Accordion with a single panel open at a time.
 */
export function Accordion({ children, ...rest }: AccordionProps) {
  const [selectedId, setSelectedId] = useState<string | undefined>();

  // When an item is clicked, we want to toggle its active state,
  // if the item is already active, we want to close it
  function onItemClick(id: string) {
    setSelectedId((currentId) => (id === currentId ? undefined : id));
  }

  return (
    <AccordionContext.Provider value={{ selectedId, onItemClick }}>
      <div {...rest}>{children}</div>
    </AccordionContext.Provider>
  );
}
